export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは8月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n10/29より<b>Walk 個人チャレンジ2024 2nd Round</b>がスタートします。もうエントリーはお済みでしょうか？豪華賞品獲得を目指し、また前回の自分を超える結果を目指していきましょう。10月の特集でもウォーキングについてご紹介しておりますので、ぜひご活用ください。\n\n",
      "down": "生活習慣スコアは8月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n10/29より<b>Walk 個人チャレンジ2024 2nd Round</b>がスタートします。もうエントリーはお済みでしょうか？豪華賞品獲得を目指し、また前回の自分を超える結果を目指していきましょう。10月の特集でもウォーキングについてご紹介しておりますので、ぜひご活用ください。\n\n",
      "equal100": "生活習慣スコアは8月に引き続き%満点%です。素晴らしい！<br>\n10/29より<b>Walk 個人チャレンジ2024 2nd Round</b>がスタートします。もうエントリーはお済みでしょうか？豪華賞品獲得を目指し、また前回の自分を超える結果を目指していきましょう。10月の特集でもウォーキングについてご紹介しておりますので、ぜひご活用ください。\n\n",
      "equal": "生活習慣スコアは8月と同じ点数です。<br>\n10/29より<b>Walk 個人チャレンジ2024 2nd Round</b>がスタートします。もうエントリーはお済みでしょうか？豪華賞品獲得を目指し、また前回の自分を超える結果を目指していきましょう。10月の特集でもウォーキングについてご紹介しておりますので、ぜひご活用ください。\n\n"
    },
    "steps": {
      "up": "歩数スコアは8月より%d点上がりました。この調子で頑張りましょう！<br>\nウォーキング前にストレッチをしていますか？ストレッチをすることで怪我の予防だけでなく、脂肪燃焼効率を上げることができます。ストレッチのやり方は<a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n",
      "down": "歩数スコアは8月より%d点下がりました。<br>\nウォーキング前にストレッチをしていますか？ストレッチをすることで怪我の予防だけでなく、脂肪燃焼効率を上げることができます。ストレッチのやり方は<a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n",
      "equal": "歩数スコアは8月と同じ点数です。<br>\nウォーキング前にストレッチをしていますか？ストレッチをすることで怪我の予防だけでなく、脂肪燃焼効率を上げることができます。ストレッチのやり方は<a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">こちら</a>から。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは8月より%d点上がりました。素晴らしい！<br>\n10/14は❝スポーツの日❞。国民がスポーツに親しみ、健康な心身を培う日として制定された祝日です。段々と過ごしやすい気候になって来ました。外で体を動かし、心身共にリフレッシュしませんか？\n\n",
      "down": "運動のスコアは8月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n10/14は❝スポーツの日❞。国民がスポーツに親しみ、健康な心身を培う日として制定された祝日です。段々と過ごしやすい気候になって来ました。外で体を動かし、心身共にリフレッシュしませんか？\n\n",
      "equal": "運動のスコアは8月と同じ点数です。<br>\n10/14は❝スポーツの日❞。国民がスポーツに親しみ、健康な心身を培う日として制定された祝日です。段々と過ごしやすい気候になって来ました。外で体を動かし、心身共にリフレッシュしませんか？\n\n"
    },
    "meal": {
      "up": "食事のスコアは8月より%d点上がりました。<br>\n❝食欲の秋❞ともいわれるように、秋は様々な食材が旬を迎えます。旬の食材は栄養価が高いですが、食べ過ぎには注意が必要です。きのこ類や葉物野菜を一緒に摂ると食べ過ぎを防ぐことができます。\n\n",
      "down": "食事のスコアは8月より%d点下がりました。<br>\n❝食欲の秋❞ともいわれるように、秋は様々な食材が旬を迎えます。旬の食材は栄養価が高いですが、食べ過ぎには注意が必要です。きのこ類や葉物野菜を一緒に摂ると食べ過ぎを防ぐことができます。\n\n",
      "equal": "食事のスコアは8月と同じ点数です。<br>\n❝食欲の秋❞ともいわれるように、秋は様々な食材が旬を迎えます。旬の食材は栄養価が高いですが、食べ過ぎには注意が必要です。きのこ類や葉物野菜を一緒に摂ると食べ過ぎを防ぐことができます。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは8月より%d点上がりました。<br>\n睡眠時は起きている時と比べ、アルコール分解能力が1/2しかありません。アルコールが体から抜ける時間が睡眠によっても変わることを意識して、寝る直前の飲酒は控えるようにしましょう。\n\n",
      "down": "飲酒のスコアは8月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\n睡眠時は起きている時と比べ、アルコール分解能力が1/2しかありません。アルコールが体から抜ける時間が睡眠によっても変わることを意識して、寝る直前の飲酒は控えるようにしましょう。\n\n",
      "equal": "飲酒のスコアは8月と同じ点数です。<br>\n睡眠時は起きている時と比べ、アルコール分解能力が1/2しかありません。アルコールが体から抜ける時間が睡眠によっても変わることを意識して、寝る直前の飲酒は控えるようにしましょう。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは8月より%d点上がりました。<br>\n徐々に気温や湿度が下がり、日照時間も短くなることから秋は睡眠に適した季節といわれます。<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、質の高い睡眠についてチェックして、夏の疲れをリセットしましょう。\n\n",
      "down": "睡眠のスコアは8月より%d点下がりました。<br>\n徐々に気温や湿度が下がり、日照時間も短くなることから秋は睡眠に適した季節といわれます。<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、質の高い睡眠についてチェックして、夏の疲れをリセットしましょう。\n\n",
      "equal": "睡眠のスコアは8月と同じ点数です。<br>\n徐々に気温や湿度が下がり、日照時間も短くなることから秋は睡眠に適した季節といわれます。<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、質の高い睡眠についてチェックして、夏の疲れをリセットしましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは8月より%d点上がりました。<br>\n季節の変わり目は、生活環境の変化からストレスが溜まりやすくなります。お風呂の時間はシャワーで済ませず、ゆっくり湯船に浸かることでリラックス効果を高めましょう。寝る1～2時間前の入浴がおすすめです。\n\n",
      "down": "ストレスのスコアは8月より%d点下がりました。<br>\n季節の変わり目は、生活環境の変化からストレスが溜まりやすくなります。お風呂の時間はシャワーで済ませず、ゆっくり湯船に浸かることでリラックス効果を高めましょう。寝る1～2時間前の入浴がおすすめです。\n\n",
      "equal": "ストレスのスコアは8月と同じ点数です。<br>\n季節の変わり目は、生活環境の変化からストレスが溜まりやすくなります。お風呂の時間はシャワーで済ませず、ゆっくり湯船に浸かることでリラックス効果を高めましょう。寝る1～2時間前の入浴がおすすめです。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since August. Which items have you seen change?<br>\n<b>The Individual Walk Challenge 2024 2nd Round</b> will start on October 29. Have you entered yet? Let's aim to win luxurious prizes and surpass your previous results. Please take a look at our October feature on walking.\n\n",
      "down": "Your lifestyle score has gone down by %d points since August.  Which items have you seen change?<br>\n<b>The Individual Walk Challenge 2024 2nd Round</b> will start on October 29. Have you entered yet? Let's aim to win luxurious prizes and surpass your previous results. Please take a look at our October feature on walking.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in August. Excellent!<br>\n<b>The Individual Walk Challenge 2024 2nd Round</b> will start on October 29. Have you entered yet? Let's aim to win luxurious prizes and surpass your previous results. Please take a look at our October feature on walking.\n\n",
      "equal": "Your lifestyle score is the same as it was in August.<br>\n<b>The Individual Walk Challenge 2024 2nd Round</b> will start on October 29. Have you entered yet? Let's aim to win luxurious prizes and surpass your previous results. Please take a look at our October feature on walking.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since August. Keep up the good work!<br>\nDo you stretch before walking? Stretching not only helps prevent injury, but also increases fat burning efficiency. <a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">Click here</a> to learn how to stretch.\n\n",
      "down": "Your steps score has gone down %d points since August. <br>\nDo you stretch before walking? Stretching not only helps prevent injury, but also increases fat burning efficiency. <a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">Click here</a> to learn how to stretch.\n\n",
      "equal": "Your steps score is the same as it was in August. <br>\nDo you stretch before walking? Stretching not only helps prevent injury, but also increases fat burning efficiency. <a href=\"https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc\">Click here</a> to learn how to stretch.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since August. Excellent!<br>\nIt is ❝Sports Day❞ on October 14. It is a national holiday established to encourage people to become familiar with sports and to cultivate a healthy mind and body. The weather is becoming more and more pleasant. Why don't you go outside and refresh your mind and body?\n\n",
      "down": "Your exercise score has gone down %d points since August. Let's take a look back at the last month.<br>\nIt is ❝Sports Day❞ on October 14. It is a national holiday established to encourage people to become familiar with sports and to cultivate a healthy mind and body. The weather is becoming more and more pleasant. Why don't you go outside and refresh your mind and body?\n\n",
      "equal": "Your exercise score is the same as it was in August. <br>\nIt is ❝Sports Day❞ on October 14. It is a national holiday established to encourage people to become familiar with sports and to cultivate a healthy mind and body. The weather is becoming more and more pleasant. Why don't you go outside and refresh your mind and body?\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since August.<br>\nAutumn is also known as ❝the season of appetite,❞ when a variety of foods are in season. In-season foods are high in nutritional value, but one must be careful not to overeat. Consuming mushrooms and leafy greens together can help prevent overeating.\n\n",
      "down": "Your food score has gone down %d points since August.<br>\nAutumn is also known as ❝the season of appetite,❞ when a variety of foods are in season. In-season foods are high in nutritional value, but one must be careful not to overeat. Consuming mushrooms and leafy greens together can help prevent overeating.\n\n",
      "equal": "Your food score is the same as it was in August. <br>\nAutumn is also known as ❝the season of appetite,❞ when a variety of foods are in season. In-season foods are high in nutritional value, but one must be careful not to overeat. Consuming mushrooms and leafy greens together can help prevent overeating.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since August.<br>\nWhen sleeping, the body has only 1/2 the capacity to break down alcohol compared to when awake. Be aware that the time it takes for alcohol to leave the body also depends on sleep, and try to avoid drinking alcohol just before going to bed.\n\n",
      "down": "Your alcohol score has gone down %d points since August. Be careful not to drink too much.<br>\nWhen sleeping, the body has only 1/2 the capacity to break down alcohol compared to when awake. Be aware that the time it takes for alcohol to leave the body also depends on sleep, and try to avoid drinking alcohol just before going to bed.\n\n",
      "equal": "Your alcohol score is the same as it was in August. <br>\nWhen sleeping, the body has only 1/2 the capacity to break down alcohol compared to when awake. Be aware that the time it takes for alcohol to leave the body also depends on sleep, and try to avoid drinking alcohol just before going to bed.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since August.<br>\nWith gradually lowering temperatures and humidity, and shorter daylight hours, fall is said to be a good time for sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Check here</a> to learn more about quality sleep and reset your summer fatigue.\n\n",
      "down": "Your sleep score has gone down %d points since August.<br>\nWith gradually lowering temperatures and humidity, and shorter daylight hours, fall is said to be a good time for sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Check here</a> to learn more about quality sleep and reset your summer fatigue.\n\n",
      "equal": "Your sleep score is the same as it was in August. <br>\nWith gradually lowering temperatures and humidity, and shorter daylight hours, fall is said to be a good time for sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Check here</a> to learn more about quality sleep and reset your summer fatigue.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since August.<br>\nDuring the change of seasons, stress can easily build up due to changes in our living environment. Do not take a shower during bath time, but soak slowly in a bathtub to enhance relaxation. Taking a bath one to two hours before going to bed is recommended.\n\n",
      "down": "Your stress score has gone down %d points since August.<br>\nDuring the change of seasons, stress can easily build up due to changes in our living environment. Do not take a shower during bath time, but soak slowly in a bathtub to enhance relaxation. Taking a bath one to two hours before going to bed is recommended.\n\n",
      "equal": "Your stress score is the same as it was in August. <br>\nDuring the change of seasons, stress can easily build up due to changes in our living environment. Do not take a shower during bath time, but soak slowly in a bathtub to enhance relaxation. Taking a bath one to two hours before going to bed is recommended.\n\n"
    }
  }
}